<template>
  <v-list v-if="items.length > 0" :lines="threeLine ? 'three' : 'two'" v-bind="$attrs">
    <template v-for="(item, index) in itemsWithKey" :key="item.id || item.key || item.name || index">
      <slot name="item" v-bind="{ item, first: index === 0, last: index === items.length - 1, index }"></slot>

      <v-divider v-if="smallScreen && index !== items.length - 1" :inset="!smallScreen" />
    </template>
    <slot />
  </v-list>
  <div v-else-if="emptyText" class="py-4">
    {{ emptyText }}
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed } from 'vue';

const props = defineProps<{
  items: T[];
  emptyText?: string;
  threeLine?: boolean;
}>();

// The generic type signature should be generic="T extends { id?: number | string; key?: string; name?: string }"
// However this forces all callers to have at least one of these properties when none are actually required
// There may be a better way of acheiving this, but this seems safe to cast as all the properties are optional
const itemsWithKey = computed(() => props.items as (T & { id?: number | string; key?: string; name?: string })[]);
</script>
